/*
 * @Description: 静态路由
 * @Author: Pancras
 * @Date: 2019-10-25 16:16:18
 * @LastEditors: Pancras
 * @LastEditTime: 2023-02-01 12:09:02
 */
import layoutHeaderAside from '@/layout/header-aside'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: '/',
    redirect: { name: 'index' },
    component: layoutHeaderAside,
    children: [
      // 首页
      {
        path: 'index',
        name: 'index',
        meta: {
          auth: true,
          title: '首页'
        },
        component: _import('system/index')
      },
      {
        path: '/mall/member/member/MallMemberDetail',
        title: '会员详情',
        name: 'member-detail',
        meta: { title: '会员详情' },
        component: _import('mall/member/member/MallMemberDetail')
      },
      // {
      //   path: '/mall/shop/store/index',
      //   title: '店铺列表',
      //   name: 'store-list',
      //   meta: { title: '店铺列表' },
      //   component: _import('mall/shop/store/index')
      // },
      {
        path: '/mall/shop/store/view',
        title: '店铺详情',
        name: 'store-detail',
        meta: { title: '店铺详情' },
        component: _import('mall/shop/store/view')
      },
      {
        path: '/mall/operation/wapList/wap',
        title: '店铺装修',
        name: 'wapList-main',
        meta: { title: '店铺装修' },
        component: _import('mall/operation/wapList/wap/main')
      },
      {
        path: '/mall/operation/pcFloor/renovation',
        title: '装修模板',
        name: 'renovation',
        meta: { title: '装修模板' },
        component: _import('mall/operation/pcFloor/renovation')
      },
      {
        path: '/mall/goods/category/parameter',
        title: '参数绑定',
        name: 'parameter',
        meta: { title: '参数绑定' },
        component: _import('mall/goods/category/parameter')
      },
      // 系统 前端日志
      {
        path: 'log',
        name: 'log',
        meta: {
          title: '前端日志',
          auth: true
        },
        component: _import('system/log')
      },
      // 系统 站内信
      {
        path: 'message',
        name: 'message',
        meta: {
          title: '我的站内信',
          auth: true
        },
        component: _import('system/message')
      },
      // 流程图
      {
        path: '/activti/detail/:id',
        name: 'detail',
        component: () => import('@/views/activiti/manage/model/components/ModelDetail'),
        meta: { title: '模型图' }
      },
      // 刷新页面 必须保留
      {
        path: 'refresh',
        name: 'refresh',
        hidden: true,
        component: _import('system/function/refresh')
      },
      // 修改个人信息
      {
        path: '/info',
        name: 'info',
        component: () => import('@/views/system/authority/user/info'),
        meta: {
          title: '个人信息',
          auth: true,
          cache: true
        }
      },
      // 修改组织信息
      {
        path: '/DeptInfo',
        name: 'deptInfo',
        component: () => import('@/views/system/authority/dept/DeptInfo'),
        meta: {
          title: '组织信息',
          auth: true,
          cache: true
        }
      },

      // 页面重定向 必须保留
      {
        path: 'redirect/:route*',
        name: 'redirect',
        hidden: true,
        component: _import('system/function/redirect')
      },
      // 管理员选择基差点价的买家
      {
        path: '/basis',
        name: 'basis',
        hidden: true,
        component: () => import('@/views/trading/goods/basicTradeViewBuyer'),
        meta: {
          title: '买家意向',
          auth: true,
          cache: true
        }
      },
      // 管理员报名竞价交易会自营商品
      {
        path: '/selfFair',
        name: 'selfFair',
        hidden: true,
        component: () => import('@/views/trading/fair/OneAuctionApply'),
        meta: {
          title: '买家意向',
          auth: true,
          cache: true
        }
      }
    ]
  }
]

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: _import('system/login')
  },
  // 锁屏页
  {
    path: '/lock',
    name: 'lock',
    component: _import('system/lock')
  }
]

/**
 * 错误页面
 */
const errorPage = [
  {
    path: '*',
    name: '404',
    component: _import('system/error/404')
  }
]

// 导出需要显示菜单的
export const frameInRoutes = frameIn
// 导出需要显示菜单的
export const frameOutRoutes = frameOut
// 404 单独导出
export const errorPageRoute = errorPage

// 重新组织后导出
export default [
  ...frameIn,
  ...frameOut
]
