/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2019-10-24 14:17:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-05 13:37:23
 */
import { Message, MessageBox } from 'element-ui'
import util from '@/libs/util.js'
import router from '@/router'
import { _LoginByAccount, _Logout, _GetUserInfo, _RefreshToken } from '@api/sys.login'
import { _GetByUsername } from '@/api/sys.login'
import { _GetTenantNoLogin } from '@/api/admin/tenant'
import cookies from '@/libs/util.cookies'

export default {
  namespaced: true,
  actions: {
    /**
     * @description  使用用户账户登录
     * @param {Object} context
     * @param {Object} payload username {String} 账户
     * @param {Object} payload password {String} 密码
     * @param {Object} payload route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    loginByAccount ({ rootState, dispatch }, loginForm) {
      return new Promise((resolve, reject) => {
        // 对 loginForm 对象指定字段加密
        let form = util.security.objectEncryption({
          data: loginForm,
          param: ['password']// 需要加密的字段
        })
        _GetByUsername(loginForm.username).then(res => {
          if (res) {
            util.sessions.set('phone', res.phone)
            _GetTenantNoLogin(res.tenantId).then(ress => {
              let date = new Date()
              date.setMonth(date.getMonth() + 1)
              util.cookies.set('tenantId', ress.id, { expires: date })
              cookies.set('tenantLogo', ress.logo, { expires: date })
              cookies.set('tenantName', ress.name, { expires: date })
              // 开始请求登录接口
              _LoginByAccount(form)
                .then(async res => {
                  // 从返回结果中获取数据
                  const data = res.data
                  // 设置用户 ID
                  util.sessions.set('uuid', data.user_id)
                  util.cookies.set('uuid', data.user_id)
                  // 定义 token 对象
                  let token = {
                    accessToken: data.access_token,
                    refreshToken: data.refresh_token,
                    expriresOn: util.getExpiresOn(data.expires_in)
                  }
                  // 使用 session 存储 token
                  util.sessions.set('token', JSON.stringify(token))
                  // 获取并存储用户信息
                  await dispatch('getUserInfo')
                  // 获取并存储用户菜单
                  await dispatch('d2admin/menu/getUserMenu', null, { root: true })
                  // 加载用户数据
                  await dispatch('d2admin/account/load', null, { root: true })
                  // 获取最新数据字典
                  dispatch('d2admin/dict/init', null, { root: true })
                  // 结束
                  resolve()
                })
                .catch(err => {
                  console.log('err: ', err)
                  reject(err)
                })
            }).catch((terr) => {
              reject(terr)
            })
          } else {
            Message.error('用户名不存在')
          }
        }).catch((uerr) => {
          reject(uerr)
        })
      })
    },
    /**
     * @description 刷新用户 token
     * @param {Object} context
     */
    refreshToken ({ commit, state }) {
      let refreshToken = util.getToken().refreshToken// 获取刷新令牌
      return new Promise((resolve, reject) => {
        _RefreshToken(refreshToken).then(response => {
          const data = response.data
          // 定义 token 对象
          let token = {
            accessToken: data.access_token,
            refreshToken: data.refresh_token,
            expriresOn: util.getExpiresOn(data.expires_in)
          }
          // 使用 session 存储 token
          util.sessions.set('token', JSON.stringify(token))
          resolve(token)
        }).catch(error => {
          reject(error)
        })
      })
    },
    /**
     * @description 注销用户并返回登录页面
     * @param {Object} context
     * @param {Object} payload confirm {Boolean} 是否需要确认
     */
    logout ({ commit, dispatch }, { confirm = false } = {}) {
      /**
       * @description 注销
       */
      function logout () {
        // 清空用户 session
        util.sessions.clear()
        // 清空 vuex 用户信息
        dispatch('d2admin/user/setInfo', {}, { root: true })
        // 清空 vuex 用户角色信息
        dispatch('d2admin/user/setRoles', {}, { root: true })
        // 清空 vuex 用户权限信息
        dispatch('d2admin/user/setPermissions', {}, { root: true })
        // 清空 vuex 用户菜单信息
        dispatch('d2admin/user/setMenu', {}, { root: true })
        // 清空 vuex 用户锁屏信息
        dispatch('d2admin/lock/clearLock', {}, { root: true })
        // 跳转路由
        router.push({ path: '/login' })
      }

      // 判断是否需要确认
      if (confirm) {
        commit('d2admin/gray/set', true, { root: true })
        MessageBox.confirm('确定要注销当前用户吗', '注销用户', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            commit('d2admin/gray/set', false, { root: true })
            // 后端退出后,前台退出
            _Logout().then(() => {
              logout()// 前端退出
            })
          })
          .catch(() => {
            commit('d2admin/gray/set', false, { root: true })
            Message({
              message: '取消注销操作'
            })
          })
      } else {
        logout()
      }
    },
    /**
     * @description 获取用户信息
     * @param {Object} context
     */
    getUserInfo ({ dispatch }) {
      return new Promise((resolve, reject) => {
        _GetUserInfo().then((res) => {
          if (util.isNull(res.permissions) || util.isNull(res.permissions)) {
            return reject(new Error('您当前无任何操作权限!'))
          }
          // 存储用户信息
          dispatch('d2admin/user/setInfo', res.sysUser, { root: true })
          // 存储角色信息
          dispatch('d2admin/user/setRoles', res.roles || [], { root: true })
          // 存储权限信息
          dispatch('d2admin/user/setPermissions', res.permissions || [], { root: true })
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },

    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} context
     */
    load ({ dispatch }) {
      return new Promise(async resolve => {
        // DB -> store 加载页面过渡效果设置
        await dispatch('d2admin/transition/load', null, { root: true })
        // DB -> store 加载上次退出时的多页列表
        await dispatch('d2admin/page/openedLoad', null, { root: true })
        // DB -> store 持久化数据加载全局尺寸
        await dispatch('d2admin/size/load', null, { root: true })
        // DB -> store 加载用户菜单
        await dispatch('d2admin/menu/menuLoad', null, { root: true })
        // DB -> store 加载锁屏设置
        await dispatch('d2admin/lock/load', null, { root: true })
        // DB -> store 加载站点
        await dispatch('d2admin/site/load', null, { root: true })
        // DB -> store 加载数据字典
        await dispatch('d2admin/dict/load', null, { root: true })
        // end
        resolve()
      })
    }
  }
}
