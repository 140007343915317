/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2019-10-25 16:16:18
 * @LastEditors: Pancras
 * @LastEditTime: 2022-07-28 20:40:47
 */
// Vue
import Vue from 'vue'
import App from './App'
// 核心插件
import d2Admin from '@/plugin/d2admin'
//  图表
import VCharts from 'v-charts'
// 导入导出
import { downBlobFile } from './util'
import pluginExport from '@d2-projects/vue-table-export'
import pluginImport from '@d2-projects/vue-table-import'
// store
import store from '@/store/index'
import '@/libs/util.js'
// 按钮权限控制
import '@/libs/btn-permissions.js'
import filters from '@/libs/filters' // 工具类

// 菜单和路由设置
import router from './router'
import { frameInRoutes } from '@/router/routes'
// 对话框移动
import './api/dialog-move'

// 挂载常用全局方法，import 引入
Vue.prototype.downBlobFile = downBlobFile

// 核心插件
Vue.use(d2Admin)
Vue.use(VCharts)
Vue.use(pluginExport)
Vue.use(pluginImport)

// 配置安全密钥
window._AMapSecurityConfig = {
  securityJsCode: 'fe1af87f14b9181dfc4b64fdfcc2f4a8' //*  安全密钥
}
// 引用公共 filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
  created () {
    // 处理页面池
    this.$store.commit('d2admin/page/init', frameInRoutes)
    Vue.prototype.$bus = this // 安装全局事件总线，所有组件都能看到$bus
  },
  mounted () {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow')
    // 获取并记录用户 UA
    this.$store.commit('d2admin/ua/get')
    // 初始化全屏监听
    this.$store.dispatch('d2admin/fullscreen/listen')
  }
}).$mount('#app')
